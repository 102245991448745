<template>
  <div class="login-warp">
    <div class="logo">
      <img src="../../assets/imgs/logo.png" alt="" />
    </div>
    <div class="login-row">
      <div class="area" @click="isAreaView = true">
        <span>+{{ areaCode }}</span>
        <div class="area-down"></div>
      </div>
      <input type="number" v-model="phone" :placeholder="i18n.phone_number" />
    </div>
    <!-- <div class="login-row">
      <input type="number" v-model="code" :placeholder="i18n.captcha_code">
      <div class="login-code" @click="onCaptcha">
        <img :src="captchaUrl" />
      </div>
    </div> -->
    <div class="login-info">
      <span
        >{{ i18n.agree }}
        <span class="readme" @click="onReadMe">{{ i18n.readme }}</span>
      </span>
    </div>
    <div id="sign-in-button" class="btn" @click="onSignInSubmit">
      {{ loading ? "Loading..." : i18n.login }}
    </div>
    <div
      class="recaptcha-container"
      id="recaptcha-container"
      v-show="phone"
    ></div>
    <!-- <div class="btn" @click="onSendCode">{{i18n.login}}</div> -->
    <div class="login-foot">
      <div class="login-foot-tips">
        <span>{{ i18n.sign_in_with }}</span>
      </div>
      <div class="login-foot-imgs">
        <!-- <login-facebook @success="loadUser">
          <img src="../../assets/imgs/icon_facebook.png" alt=""/>
        </login-facebook> -->
        <!-- <login-apple v-if="system === 'ios'" @success="loadUser()">
          <button class="btn appleloginbtn2" id="sign-in-with-apple-button">
            <img src="../../assets/imgs/icon_applepay.png" alt="">
            <span>{{ i18n.applelogin }}</span>
          </button>
        </login-apple> -->
        <login-google @success="loadUser">
          <div id="buttonDiv"></div>
        </login-google>
      </div>
    </div>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <login-code
      v-if="isLoginCode"
      @submit="onVerifyCode"
      @again="onBack"
      @back="onBack"
    ></login-code>
  </div>
</template>

<script>
import { SelectArea } from "../../components/select"
import { generateUUID } from "../../utils/index"
import { baseURL } from "../../utils/http"
import {
  USER_CAPTCHA,
  LOGIN_CODE,
  SEND_CODE,
  USER_INFO,
  LOGIN_FIREBASE
} from "../../apis/user"
import { LoginCode, LoginGoogle } from "../../components/login"
import { mapState } from "vuex"
import AreaCodeDate from "@/utils/area_code.js"
import {
  getAuth,
  signInAnonymously,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from "@firebase/auth"
import { initializeApp } from "@firebase/app"
const firebaseConfig = {
  apiKey: "AIzaSyB2Y6dTz49BnkQYls5ywP1EUKxa9HJ3d-Y",
  authDomain: "charge24ussd-e5f45.firebaseapp.com",
  projectId: "charge24ussd-e5f45",
  storageBucket: "charge24ussd-e5f45.appspot.com",
  messagingSenderId: "824245410877",
  appId: "1:824245410877:web:72aa33a27d19ed5ed05e54",
  measurementId: "G-WGETC62JGE"
}
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
export default {
  components: {
    SelectArea,
    LoginCode,
    // LoginFacebook,
    // LoginApple,
    LoginGoogle
  },
  computed: {
    ...mapState(["user", "browser", "system"]),
    i18n() {
      return this.$t("login")
    }
  },
  watch: {
    user(val, oldVal) {
      if (val && !oldVal) {
        this.$router.push("/")
      }
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "254",
      phone: "",
      code: "",
      captchaUrl: "",
      uuid: "",
      isLoginCode: false,
      loading: false
    }
  },
  methods: {
    onReadMe() {
      window.open(process.env.VUE_APP_PACT)
    },
    onSelct(val) {
      this.areaCode = val
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogin(code) {
      this.$loading(true)
      this.$post(
        LOGIN_CODE,
        {
          mobile: this.areaCode + this.phone,
          verifyCode: code,
          areaCode: this.areaCode,
          registAndLoginType: 1
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        }
      )
    },
    onSendCode() {
      this.$loading(true)
      this.$get(
        SEND_CODE,
        {
          mobile: this.areaCode + this.phone,
          uuid: this.uuid,
          code: this.code,
          useDebug: "true"
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          this.isLoginCode = true
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onBack() {
      this.isLoginCode = false
      // this.onCaptcha()
      console.log("返回重新加载验证码")
      const appVerifier = window.recaptchaVerifier
      appVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId
      })
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (res) => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit("user", res.user)
            this.$toast(this.i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (err) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit("token", null)
          })
        }
      )
    },
    loginByFirebase(userInfo) {
      this.$loading(true)
      this.$post(
        LOGIN_FIREBASE,
        {
          idToken: userInfo.accessToken,
          uid: userInfo.uid,
          displayName: "",
          email: "",
          photoUrl: "",
          phoneNumber: userInfo.phoneNumber.replace("+", "")
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    },
    onSignInSubmit() {
      if (this.loading) {
        return
      }
      if (!this.phone) {
        this.$toast("Please input your phone number.")
        return
      }
      console.log("login")
      this.loading = true
      const phoneNumber = "+" + this.areaCode + this.phone
      const appVerifier = window.recaptchaVerifier
      const vm = this
      console.log("phoneNumber", phoneNumber)
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          console.log(confirmationResult)
          // 验证成功，输入验证码
          window.confirmationResult = confirmationResult
          vm.loading = false
          this.isLoginCode = true
        })
        .catch((error) => {
          vm.loading = false
          // 验证出错，重新加载验证码
          console.log("sigin error", error)
          appVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId
          })
        })
      // signInAnonymously(auth)
      //   .then((result) => {
      //     console.log("signInAnonymously")
      //     // 验证成功，输入验证码
      //     window.confirmationResult = result
      //     vm.loading = false
      //     this.isLoginCode = true
      //   })
      //   .catch((error) => {
      //     console.log("~ errorMessage:", error)
      //   })
    },
    onVerifyCode(code) {
      console.log("verify", code)
      const vm = this
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user
          console.log("user", user)
          vm.loginByFirebase(user)
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log("verify error", error)
        })
    }
  },
  created() {
    if (this.browser === "wechat") {
      this.$router.push("/login_wechat")
    } else if (this.browser === "alipay") {
      this.$router.push("/login_alipay")
    }
  },
  mounted() {
    // this.onCaptcha()
    const vm = this
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          console.log("response", response)
          vm.onSignInSubmit()
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          console.log("expired-callback")
        }
      },
      auth
    )
    window.onSignInSubmit = this.onSignInSubmit
  }
}
</script>

<style lang="less">
.recaptcha-container {
  width: min-content;
  margin: 40px auto 0;
}
.login-warp {
  height: 100vh;
  background-color: #fff;
  .logo {
    padding: 100px 0 40px 0;
    img {
      display: block;
      margin: 0 auto;
      width: 250px;
    }
  }
  .login-row {
    margin: 36px 96px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 4px solid #000;
    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    input {
      font-size: 32px;
      width: 300px;
      flex: 1;
      height: 80px;
      padding: 0 20px;
      margin: 0;
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0);
    }
    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
    .login-code {
      width: 180px;
      height: 60px;
      background-image: linear-gradient(
        to right,
        rgba(194, 194, 194, 1),
        rgba(252, 252, 252, 1)
      );
      img {
        width: 180px;
        height: 60px;
      }
    }
  }
  .btn {
    margin: 60px 96px 0;
  }
  .login-foot {
    position: fixed;
    left: 96px;
    right: 96px;
    bottom: 48px;
    &-tips {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        z-index: 999;
        background: #fff;
        padding: 28px;
      }
      &::after {
        position: absolute;
        content: " ";
        height: 1px;
        width: 100%;
        background-color: #000;
        z-index: 99;
      }
    }
    .appleloginbtn2 {
      width: 80px;
      height: 80px;
      background: #fff;
      color: #000;
      margin: 0;
      padding: 0;
      border: 1px solid #dadce0;
      border-radius: 50%;
      img {
        width: 50px;
      }
    }
    &-imgs {
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        margin: 0 20px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .login-info {
    font-size: 24px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 33px;
    display: flex;
    justify-content: flex-start;
    padding: 0 96px;
  }
  .login-info .readme {
    font-weight: 500;
    color: rgba(52, 125, 255, 1);
    margin-left: 10px;
    display: inline;
  }
}
</style>
