<template>
  <page :title="i18n.title">
    <div class="confirm-warp">
      <div class="confirm-info">
        <div class="confirm-title">{{ i18n.pay_label }}</div>
        <div class="confirm-price">
          {{ currency }}{{ payMode === "creditcard" ? cabinet.yajin : price }}
        </div>
        <div class="confirm-icon">
          <img src="../../assets/imgs/icon_tips.png" />
          <span>{{
            payType === "YJ"
              ? i18n.price_tips
              : i18n.auth_tips.format(currency, price)
          }}</span>
        </div>
      </div>
      <div class="confirm-payment-label">
        <span>{{ i18n.pay_type }}</span>
      </div>
      <div class="confirm-payment">
        <!-- <div class="confirm-payment-item" @click="payMode='paypal'">
          <div class="confirm-payment-item-info">
            <img src="../../assets/imgs/icon_paypal.png" />
            <div class="content">
              <div class="title">Paypal</div>
            </div>
          </div>
          <div class="confirm-payment-item-checked">
            <img v-if="payMode==='paypal'" src="../../assets/imgs/checkbox_selected.png" />
            <img v-else src="../../assets/imgs/checkbox.png" />
          </div>
        </div> -->
        <template v-for="item in payModeList">
          <div
            v-if="
              item.phone === 'all' ||
              item.phone === system ||
              item.phone === browser ||
              (item.phone === 'phone' && system !== '')
            "
            :key="item.pId"
            class="confirm-payment-item"
            @click="onPayMode(item)"
          >
            <div class="confirm-payment-item-info">
              <img
                :src="
                  item.pPaymentType === 'GOOGLE_PAY' ||
                  item.pPaymentType === 'STRIPE_GOOGLE'
                    ? require('../../assets/imgs/icon_googlepay.png')
                    : item.pPaymentType === 'APPLE_PAY' ||
                      item.pPaymentType === 'STRIPE_APPLE'
                    ? require('../../assets/imgs/icon_applepay.png')
                    : require('../../assets/imgs/icon_creditcard.png')
                "
              />
              <div class="content">
                <div class="title">{{ item.label }}</div>
              </div>
            </div>
            <div class="confirm-payment-item-checked">
              <img
                v-if="
                  payMode === item.pPaymentType &&
                  item.currencySymbol === currencySymbol
                "
                src="../../assets/imgs/checkbox_selected.png"
              />
              <img v-else src="../../assets/imgs/checkbox.png" />
            </div>
          </div>
        </template>
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onPay" :disabled="disabled">
          {{ i18n.submit }}
        </div>
      </div>
      <popup-warp v-model="isCreditcard">
        <div>
          <div class="creditcard-input">
            <div id="credit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripeSetup">
            {{ i18n.paynow }}
          </div>
        </div>
      </popup-warp>
      <popup-warp v-model="MPESAPhoneShow">
        <div class="confirm-row">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phone"
            :placeholder="$t('login.phone_number')"
          />
        </div>
        <div class="btn creditcard-btn" @click="onPopupPay">
          {{ i18n.paynow }}
        </div>
      </popup-warp>
    </div>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>

    <van-dialog
      v-model="m_pesa_pay_info.show"
      :title="i18nMsg.title"
      show-cancel-button
      :before-close="onMPesaPayConfirm"
      confirm-button-color="#73D146"
      :confirm-button-text="m_pesa_pay_info.confirm"
      :cancel-button-text="i18nMsg.cancel"
    >
      <p style="margin: 20px; color: #666">{{ i18n.SMSConfirm }}</p>
    </van-dialog>
  </page>
</template>

<script>
import { SelectArea } from "../../components/select"
import { mapState } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import {
  STRIPE_PREPAY_SPTOKEN,
  STRIPE_PAYORDER_SPTOKEN,
  PAYPAL_PREAUTH,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREAUTH_AUTO_CONFIRM,
  PAYMODE,
  STRIPE_PAYORDER,
  STRIPE_RECHARGE_WALLET,
  MPESA_DEPOSIT_RENT,
  MPESA_BALANCE_RENT,
  MPESA_RECHARGE,
  MPESA_PAYORDER,
  MPESA_PAYCONFIRM,
  MOPEPE_RECHARGE,
  MOPEPE_PAY,
  MOPEPE_CONFIRM
} from "../../apis/payment"
import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp,
    SelectArea
  },
  computed: {
    ...mapState(["system", "browser", "cabinet", "user", "qrcode", "wallet"]),
    i18n() {
      return this.$t("confirm")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else {
        return this.user.pcurrencyName
      }
    }
  },
  watch: {
    payMode(val, oldVal) {
      console.log("payMode", val)
      if (
        val === "APPLE_PAY" ||
        val === "STRIPE_APPLE" ||
        val === "STRIPE_GOOGLE" ||
        val === "GOOGLE_PAY"
      ) {
        if (!this.stripePayRequest) {
          this.disabled = true
          this.$loading(true)
          this.getStripeToken()
        } else {
          this.disabled = false
        }
      } else {
        this.disabled = false
      }
    },
    stripePayRequest(val, oldVal) {
      this.disabled = !val
    }
  },
  data() {
    return {
      areaCode: "254",
      price: 0,
      payType: "YJ",
      formId: "",
      cabinetID: "",
      yaJinType: null,
      pdailiId: "",
      payMode: "STRIPE_CARD",
      pId: "", // 代理或平台支付配置id
      currencySymbol: "$",
      country: "US", // 国家
      currencyname: "usd", // 货币类型
      publicKey: "", // 支付通道的公钥
      disabled: false,
      stripePayRequest: null,
      isCreditcard: false,
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      creditcard: null,
      clientSecret: null,
      MPESAPhoneShow: false,
      isAreaView: false,
      payModeList: [],
      orderId: "",
      phone: "",
      m_pesa_pay_info: { confirm: "", show: false }
    }
  },
  methods: {
    onSelct(val) {
      this.areaCode = val
    },
    // 支付方式选中
    onPayMode(v) {
      console.log("支付方式===", v)
      this.payMode = v.pPaymentType
      this.currencySymbol = v.currencySymbol
      this.currencyname = v.configDetails.currency
      this.publicKey = v.publicKey
      this.country = v.configDetails.accountCountry
    },
    getStripeToken() {
      getToken(this.price, this.country, this.currencyname, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet && this.payType === "YJ") {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.pId // 代理或平台支付配置id
        }
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER_SPTOKEN
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          stripeToken: token,
          paymentId: this.pId
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onPopupPay() {
      if (this.payMode === "M-PESA") {
        this.onSMSPay()
      }
    },
    onPay() {
      if (this.payMode === "PAYPAL") {
        this.onPaypal()
      } else if (
        this.payMode === "APPLE_PAY" ||
        this.payMode === "STRIPE_APPLE" ||
        this.payMode === "STRIPE_GOOGLE" ||
        this.payMode === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (this.payMode === "STRIPE_CARD") {
        this.onCreditcard(this.publicKey)
      } else if (this.payMode === "M-PESA") {
        console.log(this.cabinet)
        this.MPESAPhoneShow = true
        // if (this.user && this.user.pmobile) {
        //   this.phone = this.user.pmobile.split(this.user.alUserId)[1]
        // }
      } else {
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
          this.$router.replace("/result")
        }, 3000)
      }
    },
    onPaypal() {
      this.$loading(true)
      let url =
        PAYPAL_PREAUTH + `/${this.qrcode || this.$route.query.cabinetID}`
      this.$post(
        url,
        {
          paymentId: this.pId
        },
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            this.$loading(true)
            this.$store.commit("paypalTradeNo", resp.data.tradeNo)
            this.$store.commit("paymentId", this.pId)
            window.location.href = resp.data.approve
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPaypal()
          })
          console.log(error)
        }
      )
    },
    onSMSRent() {
      // 肯尼亚支付通道的
      console.log("肯尼亚支付")
      this.$loading(true)
      this.$get(
        `${MPESA_BALANCE_RENT}?qrcode=${this.cabinetID}`,
        {},
        (resp) => {
          this.$loading(false)
          if (this.payType !== "YJ") {
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=MPESA&payType=${this.payType}`
            )
            return
          }
          this.$router.replace(
            `/result?tradeNo=${resp.data.orderId}&payMode=MPESA`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onMPesaPayConfirm(action, done) {
      if (action === "cancel") {
        clearInterval(this.m_pesa_timer)
      }
      if (action === "confirm") {
        return done(false)
        // this.onSMSCheckOrder(this.m_pesa_pay_info.out_trade_no)
      }
      done()
    },
    onSMSPay() {
      // 肯尼亚支付通道的
      console.log("肯尼亚支付")
      if (!this.phone) {
        this.$toast(this.i18nMsg.no_phone)
      }
      let url = ""
      let datas = {}
      if (this.payType === "YJ") {
        url = `${MPESA_RECHARGE}`
        datas = {
          amount: this.price,
          rechargeType: "balance",
          paymentId: this.pId,
          phoneNumber: this.areaCode + this.phone
        }
      } else {
        url = `${MPESA_PAYORDER}`
        datas = {
          outTradeNo: this.orderId,
          couponNo: "",
          paymentId: this.pId,
          phoneNumber: this.areaCode + this.phone
        }
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          this.MPESAPhoneShow = false
          console.log(resp)
          this.m_pesa_pay_info = {
            ...resp.data,
            ...this.m_pesa_pay_info,
            count: 15,
            show: true,
            confirm: "(00s)"
          }
          this.m_pesa_timer = setInterval(() => {
            this.m_pesa_pay_info.confirm = `(${this.m_pesa_pay_info.count}s)`
            this.m_pesa_pay_info.count--
            if (this.m_pesa_pay_info.count < 0) {
              this.m_pesa_pay_info.show = false
              clearInterval(this.m_pesa_timer)
              this.onSMSCheckOrder(resp.data.out_trade_no)
            }
          }, 1000)

          // this.$confirm({
          //   title: this.i18nMsg.title,
          //   content: this.i18n.SMSConfirm,
          //   confirmText: this.i18nMsg.confirm,
          //   cancelText: this.i18nMsg.cancel
          // }).then(() => {
          //   this.onSMSCheckOrder(resp.data.out_trade_no)
          //   // if (this.payType === 'CZ' && this.cabinetID) {
          //   //   this.$router.replace(`/result?tradeNo=${resp.data.out_trade_no}&payMode=mpesa&payType=${this.payType}`)
          //   //   return
          //   // }
          //   // this.$router.replace(`/result?tradeNo=${resp.data.out_trade_no}&payMode=mpesa`)
          // })
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    onSMSCheckOrder(tradeNo) {
      this.loading = true
      this.$get(
        `${MPESA_PAYCONFIRM}?outTradeNo=${tradeNo}`,
        {},
        (resp) => {
          if (resp.data && resp.data.orderStatus !== 0) {
            this.onSMSRent()
          } else {
            const orderUrl = `/result?tradeNo=${resp.data.orderId}&payType=${this.payType}&payMode=m-pesa`
            this.$router.push(orderUrl)
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.$t("result.msg_fail_content"),
            confirmText: this.i18nMsg.confirm,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onSMSCheckOrder(tradeNo)
          })
          // if (error === '正在请求中') {
          //   setTimeout(() => {
          //     this.onSMSCheckOrder(tradeNo)
          //   }, 1000)
          // } else {
          console.log(error)
          //   this.$toast(error)
          // }
        }
      )
    },
    onCreditcard(publicKey) {
      // console.log('publicKey', publicKey)
      let url = ""
      let datas = {}
      if (this.cabinet && this.payType === "YJ") {
        url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.qrcode}`
        datas = {
          paymentId: this.pId,
          amount: this.price
        }
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          paymentId: this.pId
        }
      } else {
        url = STRIPE_RECHARGE_WALLET
        datas = {
          amount: this.price,
          paymentId: this.pId
        }
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: "tabs"
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard(publicKey)
          })
          console.log(error)
        }
      )
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      window.stripe
        .confirmPayment({
          elements: vm.creditcard,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: window.location.href
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          }
        })
    },
    confirmSetup(id) {
      this.$loading(true)
      this.$get(
        // `${STRIPE_PREAUTH_AUTO_CONFIRM}`,
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`,
        {},
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (this.payType !== "YJ") {
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=${this.payType}`
            )
            return
          }
          this.$router.replace(
            `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    getPayMode() {
      console.log("hahah", this.cabinet, this.wallet)
      this.$loading(true)
      let params = ""
      if (this.payType === "CZ") {
        params = this.wallet
          ? this.wallet.pDailiId
          : this.cabinet.userWallet.pDailiId
      }
      this.$get(
        this.payType !== "CZ"
          ? `${PAYMODE}${`?id=${this.qrcode || this.$route.query.cabinetID}`}`
          : `${PAYMODE}?agentId=${params}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              } else if (v.pPaymentType === "PAYPAL") {
                v.label = "Paypal"
                v.phone = "all"
              } else if (v.pPaymentType === "M-PESA") {
                v.label = "SMS Payment"
                v.phone = "all"
              }
              // list.push(v)
            })
            this.payModeList = list
            if (list.length > 0) {
              // let item = list.filter(v => {
              //   return v.pPaymentType === 'STRIPE_CARD'
              // })
              this.payMode = list[0].pPaymentType
              this.currencySymbol = list[0].currencySymbol // 货币符号
              this.currencyname = list[0].configDetails.currency // 货币类型
              this.publicKey = list[0].publicKey
              this.pId = list[0].pId
              this.country = list[0].configDetails.accountCountry
              console.log(this.publicKey, this.payMode)
              if (list[0].pPaymentType !== "M-PESA") {
                this.loadStripeScript(list[0].publicKey)
              }
            }
            console.log("list======", list)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    }
  },
  created() {
    let price = this.$route.query.price
    let formId = this.$route.query.formId
    this.price = price ? Number(price) : 0
    this.orderId = this.$route.query.outTradeNo
    this.formId = formId
    this.payType = this.$route.query.payType
    this.cabinetID = this.$route.query.cabinetID
    this.yaJinType = this.$route.query.yaJinType
    const payId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    )
    console.log("created===", payId)
    if (payId) {
      this.confirmSetup(payId)
    }
    this.getPayMode()
    console.log(this)
  }
}
</script>

<style lang="less">
.noClick {
  opacity: 0.5;
  pointer-events: none; /* 禁止鼠标点击 */
}
.confirm-warp {
  overflow-y: scroll;
}
.confirm-info {
  background: #ffffff;
  text-align: center;
  padding: 40px;
}
.confirm-title {
  font-size: 36px;
}
.confirm-price {
  font-size: 80px;
  color: #fc0d1b;
}
.confirm-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  & span {
    text-align: left;
    font-size: 28px;
    color: #999999;
  }
}
.confirm-payment {
  background: #ffffff;
  padding: 30px;
  &-label {
    padding: 10px 20px;
    font-size: 28px;
    color: #999999;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    border-bottom: 1px solid #dddddd;
    & img {
      width: 40px;
      height: 40px;
    }
    &-info {
      display: flex;
      align-items: center;
      flex: 1;
      & .content {
        padding-left: 10px;
      }
      & .sub_title {
        font-size: 28px;
        color: #fec348;
      }
    }
  }
}
.confirm-remark {
  padding: 0 32px;
}
.confirm-remark .title {
  margin-top: 40px;
  font-size: 32px;
}
.confirm-remark .desc {
  font-size: 28px;
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  word-break: normal !important;
  white-space: normal !important;
}
.confirm-btns {
  padding: 60px 40px 20px;
}
.confirm-btns .btn {
  margin-top: 20px;
}
/* #ifdef MP-WEIXIN */
.confirm-btns button.btn {
  background-color: #65b74e;
}
/* #endif */
.confirm-checkbox {
  padding: 30px;
  & label {
    display: flex;
    align-items: center;
  }
  & span {
    color: #52ac7a;
  }
}
.btn-paypal {
  background: #fec348 !important;
}
.btn-paypal img {
  width: 277px;
  height: 60px;
  margin: 18px 0;
}
.btn-applepay {
  background: #ffffff !important;
  color: #050608;
}
.btn-applepay img {
  width: 143px;
  height: 60px;
  margin: 18px 0;
}
.btn-googlepay {
  color: #5f6368;
  background: #ffffff !important;
}
.btn-googlepay img {
  width: 145px;
  height: 60px;
  margin: 18px 0;
}
.creditcard-input {
  margin: 48px 48px 200px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}
.creditcard-btn {
  margin: 48px;
}
.confirm-row {
  margin: 36px 45px 140px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 4px solid rgb(211, 211, 211);
  border-radius: 20px;
  .area {
    width: 100px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  input {
    font-size: 32px;
    width: 300px;
    flex: 1;
    height: 80px;
    padding: 0 20px;
    margin: 0;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .area-down {
    // margin-left: 10px;
    width: 0;
    height: 0;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: black transparent transparent;
  }
  .login-code {
    width: 180px;
    height: 60px;
    background-image: linear-gradient(
      to right,
      rgba(194, 194, 194, 1),
      rgba(252, 252, 252, 1)
    );
    img {
      width: 180px;
      height: 60px;
    }
  }
}
</style>
