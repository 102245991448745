<template>
  <div class="menu-side">
    <div class="menu-side-title">
      <img src="../../assets/imgs/avatar.png" alt="" />
      <span v-if="user && user.pname">{{ user.pname | decode }}</span>
      <span v-else @click="goRouter('/login')">{{ i18n.login_tips }}</span>
    </div>
    <!-- <div class="menu-side-row" @click="goRouter('/wallet')">
      <img src="../../assets/imgs/wallet.png" alt="">
      <span>{{i18n.wallet}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/order')">
      <img src="../../assets/imgs/history.png" alt="">
      <span>{{i18n.order}}</span>
    </div> -->
    <!-- <div class="menu-side-row" @click="goRouter('/shop')">
      <img src="../../assets/imgs/shop.png" alt="">
      <span>{{i18n.buy}}</span>
    </div> -->
    <!-- <div class="menu-side-row">
      <img src="../../assets/imgs/coupon.png" alt="">
      <span>优惠券</span>
    </div> -->
    <div class="menu-side-row" @click="goRouter('/setting')">
      <!-- <img src="../../assets/imgs/setting.png" alt=""> -->
      <span>{{ i18n.setting }}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/help')">
      <!-- <img src="../../assets/imgs/help.png" alt=""> -->
      <span>{{ i18n.help }}</span>
    </div>
    <!-- <div class="menu-side-foot">
      <div class="menu-side-row" @click="onLogout">
        <! -- <img src="../../assets/imgs/logout.png" alt=""> -- >
        <span>{{i18n.logout}}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user"]),
    i18n() {
      return this.$t("mine")
    }
  },
  methods: {
    onLogout() {
      this.$store.commit("user", null)
      this.$store.commit("token", "")
      this.$router.push("/login")
    },
    goRouter(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less">
.menu-side {
  width: 550px;
  &-title {
    font-size: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0 48px;
    color: #fff;
    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 0 24px 0 48px;
    }
  }
  &-row {
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    padding-left: 80px;
    img {
      width: 40px;
      height: 40px;
      margin: 0 24px 0 68px;
    }
  }
  &-foot {
    width: 100%;
    position: absolute;
    bottom: 48px;
    color: #fff;
  }
}
</style>
